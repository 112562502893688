/* eslint-disable @typescript-eslint/no-empty-interface */
import { SimplePaletteColorOptions, alpha, experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import type { } from "@mui/material/themeCssVarsAugmentation";

// REMEMBER TO UPDATE MOBILE PROJECT TOO

/**
 * Theme and styling guidelines:
 *
 * This config is mostly for styling MUI components, but the CSS variables can also be accessed in CSS and used in other
 * components. Accessing theme variables:
 * In MUI components: sx={{ color: "primary.main" }}
 * In LESS: color: @colorPrimaryMain (needs to be set in "variables.less")
 * In CSS: color: var(--palette-primary-main)
 *
 * When creating new components, override MUI defaults or create a new variant when possible.
 *
 * Add new colors and other values here, instead of hard coding them in template or CSS. Migrate old styles here as well.
 * If the theme config API doesn't allow overriding a property, use "mui.less" instead.
 *
 * Add new variables to "variables.less" to make them easier to access in LESS using intellisense. To create accent
 * colors, use MUI's color tool. Be careful not to use Bootstrap's CSS variables, which you can see in the html element.
 *
 * For layout styles, use Grid2 from the Material UI. Check the docs for flexbox props.
 *
 * For utility classes, refer to Bootstrap's docs and the "utility.less" file. Add a new class if it doesn't already
 * exist. Make a custom component class (in "components.less") over utility classes when creating a commonly used
 * component to avoid boilerplate, if the component can be easily identified by its name or if the template gets too
 * bloated. If the component is specific to a certain module, create a module LESS file instead, under
 * "cssSource/less/module". Keep in mind that these are unrelated to React's module scope, since it doesn't support
 * LESS, but you get access to variables. See the "cssSource/less" directory for other styles. Avoid inline styles,
 * including the sx prop, for styles than can be defined using utility classes.
 *
 * These rules should be enforced in code review.
 *
 * Docs: (potentially outdated)
 * - Bootstrap Utilities: https://getbootstrap.com/docs/4.0/utilities
 * - MUI Components: https://mui.com/material-ui/react-autocomplete/
 * - MUI Theme: https://mui.com/material-ui/customization/theming/
 * - MUI Color Tool: https://m2.material.io/resources/color/
 */

// Custom variants
declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        save: true;
        primary: true;
    }
}


/**
 * properties of colors:
 * main: main color of some component.
 * light and dark: used when we need a different accent of the color for hover effects and such.
 */
export const colors = {
    primary: {
        light: "#63a4ff",
        main: "#1976d2",
        dark: "#033062"
    },
    secondary: {
        light: "#89c62b",
        main: "#50b027",
        dark: "#0b8000",
    },
    blue: {
        light: "#63a4ff",
        main: "#1976d2",
        dark: "#033062"
    },
    red: {
        light: "#ff5b56",
        main: "#ff022c",
        dark: "#c30003"
    },
    yellow: {
        light: "#fff94f",
        main: "#e2c603",
        dark: "#9e8a02"
    },
    orange: {
        light: "#faa769",
        main: "#ee7316",
        dark: "#9f3f0d",
    },
    green: {
        extraLight: "#90d12e",
        light: "#89c62b",
        main: "#50b027",
        dark: "#0b8000",
        contrastText: "#fff"
    },
    gray: {
        light: "#e3e3e3",
        main: "#828282"
    },
    lightBlue: {
        main: "#e4f7ff"
    },
    grayLight: {
        main: "#e3e3e3"
    },
    light: {
        main: "#f8f8f8"
    },
    white: {
        main: "#ffffff"
    },
    dark: {
        main: "#000000"
    },
    purple: {
        light: "#b14be8",
        main: "#902bc6",
        dark: "#681396",
    },
    fuchsia: {
        main: "#ff02e2",
        dark: "#b2019e"
    },
    brown: {
        light: "#a06a3c",
        main: "#a06a3c"
    },
    // secondary table header
    grayBlue: {
        main: "#425980"
    },
    // odd table row
    lightGrayBlue: {
        light: "#e6efff",
        main: "#e7eaef"
    },
    // TODO work order state colors, etc.
};

type ColorsType = typeof colors;

// Allows custom colors for mui components.
// They always use the "main" property of a color.
type ColorOverrideFlags = {
    [Property in keyof typeof colors]: true;
};

export type ThemeColor = keyof ColorOverrideFlags;

// For each component that wants to use custom colors, this augmentation is needed.
declare module "@mui/material" {
    interface ButtonPropsColorOverrides extends ColorOverrideFlags { }
    interface IconButtonPropsColorOverrides extends ColorOverrideFlags { }
    interface SwitchPropsColorOverrides extends ColorOverrideFlags { }
    interface SvgIconPropsColorOverrides extends ColorOverrideFlags { }
    interface ChipPropsColorOverrides extends ColorOverrideFlags { }

    interface IconButtonPropsSizeOverrides {
        xmall: true;
    }
}

declare module "@mui/material/styles" {
    interface Palette extends ColorsType { }
}

const typography: TypographyOptions = {
    body1: {
        fontFamily: "Roboto, -apple-system, BlinkMacSystemFont",
    },
    h2: {
        fontFamily: "Roboto, -apple-system, BlinkMacSystemFont",
        fontSize: 22,
        fontWeight: "bold",
        color: colors.blue.dark,
    },
    h3: {
        fontFamily: "Roboto, -apple-system, BlinkMacSystemFont",
        fontSize: 18,
        fontWeight: "bold",
        color: colors.blue.dark,
        marginBottom: 24,
    },
    h4: {
        fontSize: 16,
        fontWeight: "bold",
        color: colors.dark.main,
    },
    h5: {
        fontSize: 12,
        color: colors.gray.main,
    },
    // TODO add h1, h3, etc. and others when necessary.
};

// Styling related constants for consistency
interface StyleConstants {
    formSpacing: number;
    fieldWidthSmall: number;
    fieldWidth: number;
    fieldWidthBig: number;
}

export const STYLE_CONSTANTS: StyleConstants = {
    formSpacing: 3,
    fieldWidthSmall: 126,
    fieldWidth: 280,
    fieldWidthBig: 588,
} as const;

// get reference with correct type, so we get a compiler error if colors are not proper.
const customColors: Record<string, SimplePaletteColorOptions> = colors;

// This is extendTheme, not createTheme.
// Check the docs (which are very bad) for what you can do.
export const theme = extendTheme(
    {
        colorSchemes: {
            light: {
                palette: {
                    // Override MUI defaults
                    primary: colors.primary, // becomes --palette-primary-light, etc.
                    error: colors.red,
                    warning: colors.yellow,
                    success: colors.green,
                    text: {
                        primary: colors.dark.main,
                        secondary: colors.gray.main,
                    },
                    // Custom values. These don't override defaults but can be used in code and CSS.
                    ...customColors,
                },
            },
        },
        typography,
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            color: colors.green.main,
                        },
                    },
                },
                defaultProps: {
                    disableRipple: true
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        "& .MuiTabs-indicator": {
                            backgroundColor: colors.green.main,
                            height: 3,
                            transition: "none"
                        },
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 3,
                },
            },
            MuiButton: {
                defaultProps: {
                    disableRipple: true
                },
                variants: [
                    {
                        props: {
                            variant: "save",
                        },
                        style: {
                            backgroundColor: colors.green.main,
                            color: colors.white.main,
                            "&:hover": {
                                color: colors.white.main,
                                backgroundColor: colors.green.light,
                            },
                            boxShadow: "var(--shadows-4)",
                        },
                    },
                    {
                        props: {
                            variant: "primary",
                        },
                        style: {
                            backgroundColor: colors.primary.main,
                            color: colors.white.main,
                            "&:hover": {
                                color: colors.white.main,
                                backgroundColor: colors.primary.light,
                            },
                            boxShadow: "var(--shadows-4)",
                        },
                    },
                ],
            },
            MuiIconButton: {
                variants: [
                    {
                        props: { size: "xmall" },
                        style: { padding: "2px" }
                    }
                ]
            },
            MuiDialogContent: {
                defaultProps: {
                    sx: { py: 4 }
                },
            },
            MuiTextField: {
                defaultProps: {
                    sx: {
                        maxWidth: "100%",
                        width: STYLE_CONSTANTS.fieldWidth,
                    },
                },
                styleOverrides: {
                    root: {
                        "& .MuiInputBase-root": {
                            backgroundColor: "#fff"
                        },
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        // bootstrap-reboot.css adds some margin that throws texts out of alignment.
                        p: {
                            marginTop: "auto",
                            marginBottom: "auto"
                        }
                    }
                }
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            backgroundColor: alpha(colors.primary.light, 0.3),
                        },
                        "&.Mui-selected:hover": {
                            backgroundColor: alpha(colors.primary.light, 0.5),
                        },
                    },
                }
            }
        },
        cssVarPrefix: "",
    },
    {
        // CSS variables that are not automatically created. You can see the variables on the html element.
        "font-normal": typography.body1.fontFamily,
        "field-width-small": STYLE_CONSTANTS.fieldWidthSmall,
        "field-width": STYLE_CONSTANTS.fieldWidth,
        "field-width-big": STYLE_CONSTANTS.fieldWidthBig,
    }
);
