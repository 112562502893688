import React, { useCallback, useMemo, useState } from "react";
import { MainLayout } from "../layout/mainLayout";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { MonitorViewMap } from "./monitorViewMap";
import { MonitorViewSidebar } from "./monitorViewSidebar";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";

import { EmployeeData, MonitorViewMainLayoutProps, VehicleData } from "../../models/monitorView/monitorViewTypes";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { WorkShiftDto } from "../../models/workShift/workShift";

export const MonitorViewMainLayout = ({
    positions,
    loading,
    workShift,
    workShifts,
    selectedVehicleId,
    selectedWorkShiftTimeSlotId,
    selectedEmployeeId,
    reloadKey,
    onRefresh,
    setSelectedVehicleId,
    setSelectedWorkShiftTimeSlot,
    setSelectedEmployeeId,
}: MonitorViewMainLayoutProps) => {
    const [hoveredWorkShiftTimeSlotId, setHoveredWorkShiftTimeSlotId] = useState<string>();
    const [selectedFilter, setSelectedFilter] = useState<number | "All">("All");
    const [tab, setTab] = useState(0);

    const employees = useAppSelector((state) => state.employees.employees);
    const showNoWorkTime = useAppSelector((state) => state.monitorView.filters.noWorkTime);
    const selectedWorkTimeTypeIds = useAppSelector((state) => state.monitorView.filters.workTimeTypeIds);
    const noWork = useAppSelector((state) => state.monitorView.filters.noWork);

    const filterByWorkTimeType = useCallback(
        (p: VehiclePositionInfo) =>
            !selectedWorkTimeTypeIds ||
            p.employeeWorkTimeTypes.some((w) => selectedWorkTimeTypeIds?.includes(w.workTimeTypeId)),
        [selectedWorkTimeTypeIds]
    );

    const applyFilters = useCallback(
        (p: VehiclePositionInfo) => {
            const hasNoWorkTime = p.employeeWorkTimeTypes.length === 0;
            const matchesWorkTimeType = filterByWorkTimeType(p);
            return (!showNoWorkTime && matchesWorkTimeType) || (showNoWorkTime && hasNoWorkTime) || matchesWorkTimeType;
        },
        [showNoWorkTime, filterByWorkTimeType]
    );

    const employeeFilter = useCallback(
        (p: VehiclePositionInfo) => {
            if (!p?.employeeWorkTimeTypes || p?.employeeWorkTimeTypes.length === 0) {
                return noWork;
            }
            const hasNoWorkTime = p.employeeWorkTimeTypes.length === 0 || p.employeeWorkTimeTypes[0].workTimeTypeId === null;
            const hasSelectedWorkTimeType = filterByWorkTimeType(p);
            return noWork ? hasNoWorkTime || hasSelectedWorkTimeType : hasSelectedWorkTimeType;
        },
        [noWork, filterByWorkTimeType]
    );

    const mapCommonData = (position: VehiclePositionInfo) => ({
        brand: position?.brand,
        carId: position?.carId,
        codriverGmt: position?.codriverGmt,
        codriverId: position?.codriverId,
        coords: { lat: position?.latitude, lng: position?.longitude },
        driverGmt: position?.driverGmt,
        driverId: position?.driverId,
        driving: position?.driving,
        drivingGmt: position?.drivingGmt,
        employeeWorkTimeTypes: position?.employeeWorkTimeTypes,
        latitude: position?.latitude,
        longitude: position?.longitude,
        registerNumber: position?.registerNumber,
        speed: position?.speed,
        timestamp: position?.timestamp,
        vehicleId: position?.vehicleId,
        workTimeTypeName: position?.employeeWorkTimeTypes[0]?.workTimeTypeName,
        workTimeTypeType: position?.employeeWorkTimeTypes[0]?.workTimeTypeType,
    });

    const mapVehicleData = (p: VehiclePositionInfo): VehicleData => ({
        ...mapCommonData(p),
        employeeName: p.employeeWorkTimeTypes[0]?.employeeName,
    });

    const mapEmployeeData = (e: EmployeeListItemDto, positions: VehiclePositionInfo[], workShifts: WorkShiftDto[]): EmployeeData => {
        const position = positions?.find((p) => p.employeeWorkTimeTypes.some((w) => w.employeeId === e.id));
        return {
            ...mapCommonData(position),
            employeeId: e.id,
            employeeName: e?.fullName,
            workShifts: workShifts,
        };
    };

    const vehicleData: VehicleData[] = useMemo(
        () =>
            positions
                ?.map(mapVehicleData)
                .sort((a, b) => Base.stringCompare(a.registerNumber, b.registerNumber))
                .sort((a, b) => Base.stringCompare(a.workTimeTypeName, b.workTimeTypeName)),
        [positions]
    );

    const filteredVehicleData = useMemo(() => {
        if (selectedFilter === "All") return vehicleData;
        return vehicleData.filter((vehicle) => vehicle.driving === selectedFilter);
    }, [vehicleData, selectedFilter]);

    const filteredEmployeeData: EmployeeData[] = useMemo(
        () =>
            employees
                ?.filter((e) => {
                    const position = positions?.find((p) => p.employeeWorkTimeTypes.some((w) => w.employeeId === e.id));
                    return employeeFilter(position);
                })
                .map((e) => mapEmployeeData(e, positions, workShifts))
                .sort((a, b) => Base.stringCompare(a.workTimeTypeName, b.workTimeTypeName)),
        [positions, employees, noWork, selectedWorkTimeTypeIds, workShifts]
    );

    const countByWorkTimeType = useMemo(() => {
        const counts = employees?.reduce((acc, e) => {
            const position = positions?.find((p) => p.employeeWorkTimeTypes.some((w) => w.employeeId === e.id));
            const workTimeTypeId = position?.employeeWorkTimeTypes[0]?.workTimeTypeId;
            if (!position || workTimeTypeId === null) {
                acc["noWork"] = (acc["noWork"] || 0) + 1;
            } else {
                acc[workTimeTypeId] = (acc[workTimeTypeId] || 0) + 1;
                acc["work"] = (acc["work"] || 0) + 1;
            }
            return acc;
        }, {} as { [key: string]: number }) || {};
        return counts;
    }, [positions, employees]);

    const countByDrivingStatus = useMemo(() => {
        const counts = positions?.reduce((acc, p) => {
            const drivingStatus = p.driving;
            acc[drivingStatus] = (acc[drivingStatus] || 0) + 1;
            return acc;
        }, {} as { [key: number]: number }) || {};
        return counts;
    }, [positions]);

    const activeEmployeesAndVehicles = useMemo(() => {
        let activeEmployees = 0;
        let activeVehicles = 0;
        positions
            ?.filter(applyFilters)
            ?.forEach(({ employeeWorkTimeTypes, driving }) => {
                if (employeeWorkTimeTypes?.length > 0) {
                    if (selectedFilter === "All" || driving === selectedFilter) {
                        activeVehicles++;
                    }
                    activeEmployees++;
                }
            });
        const totalEmployees = employees?.length || 0;
        const totalVehicles = positions?.length || 0;
        return {
            activeVehicles: `${activeVehicles}/${totalVehicles}`,
            activeEmployees: `${activeEmployees}/${totalEmployees}`,
        };
    }, [positions, applyFilters, selectedFilter, employees]);

    const createSidebarProps = <T, >(
        data: T[],
        selectedId: string,
        setSelectedId: (id: string) => void,
        additionalProps = {}
    ) => ({
            data,
            selectedId,
            setSelectedId,
            ...additionalProps,
        });

    const vehicleSidebarProps = useMemo(
        () => createSidebarProps(filteredVehicleData, selectedVehicleId, setSelectedVehicleId, { countByDrivingStatus, selectedFilter, setSelectedFilter }),
        [filteredVehicleData, selectedVehicleId, setSelectedVehicleId, countByDrivingStatus, selectedFilter, setSelectedFilter]
    );

    const employeeSidebarProps = useMemo(
        () => createSidebarProps(filteredEmployeeData, selectedEmployeeId, setSelectedEmployeeId, { countByWorkTimeType }),
        [filteredEmployeeData, selectedEmployeeId, setSelectedEmployeeId]
    );

    const workShiftSidebarProps = useMemo(
        () => createSidebarProps(
            [{ workShift }],
            selectedWorkShiftTimeSlotId,
            setSelectedWorkShiftTimeSlot,
            {
                setHoveredWorkShiftTimeSlotId,
                setSelectedVehicleId,
            }
        ),
        [workShift, selectedWorkShiftTimeSlotId, setSelectedWorkShiftTimeSlot, setHoveredWorkShiftTimeSlotId, setSelectedVehicleId]
    );

    const mapData = useMemo(() => {
        if (tab === 0) {
            return filteredVehicleData;
        } else {
            const employeeVehicleIds = new Set(filteredEmployeeData.map(e => e.vehicleId));
            return vehicleData.filter(v => employeeVehicleIds.has(v.vehicleId) || (noWork && v.employeeWorkTimeTypes.length === 0));
        }
    }, [tab, filteredVehicleData, filteredEmployeeData, vehicleData, noWork]);

    return (
        <MainLayout
            noPadding
            sideComponentSide="left"
            sideComponent={
                <MonitorViewSidebar
                    vehicleSidebarProps={vehicleSidebarProps}
                    workShiftSidebarProps={workShiftSidebarProps}
                    employeeSidebarProps={employeeSidebarProps}
                    activeEmployeesAndVehicles={activeEmployeesAndVehicles}
                    setTab={setTab}
                />
            }
            sideOverFlowHidden
        >
            <>
                <LoadingIndicator loading={loading} />
                <MonitorViewMap
                    loading={loading}
                    data={mapData}
                    onRefresh={() => onRefresh(true)}
                    selectedVehicleId={selectedVehicleId}
                    setSelectedVehicle={setSelectedVehicleId}
                    workShift={workShift}
                    reloadKey={reloadKey}
                    selectedWorkShiftTimeSlotId={selectedWorkShiftTimeSlotId}
                    hoveredWorkShiftTimeSlotId={hoveredWorkShiftTimeSlotId}
                    setSelectedWorkShiftTimeSlotId={setSelectedWorkShiftTimeSlot}
                />
            </>
        </MainLayout>
    );
};