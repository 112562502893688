import React, { useRef, useState } from "react";
import { Typography, Box, Grid, Popover } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { WorklogMain } from "../../worklog/worklogMain";
import { BoldText, Subtitle2, Text } from "../styledComponents";

interface EmployeeCardContentProps {
    effectiveDate: string;
    employeeId: string;
    driver: string;
    mainTitle: string;
    shiftDuration: string;
    timeSlotDuration: string;
    workTimeStatusIconClass: string;
    workTimeTypeName: string;
}

const EmployeeCardContent: React.FC<EmployeeCardContentProps> = ({
    effectiveDate,
    employeeId,
    driver,
    mainTitle,
    shiftDuration,
    timeSlotDuration,
    workTimeStatusIconClass,
    workTimeTypeName
}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TitleSection
                    effectiveDate={effectiveDate}
                    employeeId={employeeId}
                    mainTitle={mainTitle}
                    shiftDuration={shiftDuration}
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                <StatusSection
                    driver={driver}
                    workTimeStatusIconClass={workTimeStatusIconClass}
                    workTimeTypeName={workTimeTypeName}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <DetailsSection
                    employeeId={employeeId}
                    timeSlotDuration={timeSlotDuration}
                    workTimeStatusIconClass={workTimeStatusIconClass}
                />
            </Grid>
        </Grid>
    );
};

const TitleSection: React.FC<{
    effectiveDate: string;
    employeeId: string;
    mainTitle: string;
    shiftDuration: string;
}> = ({
    effectiveDate,
    employeeId,
    mainTitle,
    shiftDuration
}) => (
    <Grid container>
        <Grid item xs={12} sm={6}>
            <BoldText variant="h6"> { mainTitle } </BoldText>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Box sx={{ textAlign: "right " }}>
                <Subtitle2> { shiftDuration } </Subtitle2>
                {effectiveDate ? (
                    <a
                        href={`/worktimepage/details/${employeeId}/${effectiveDate}/hourbooking`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <OpenInNewIcon fontSize="small" color="primary" />
                    </a>
                ) : null}
            </Box>
        </Grid>
    </Grid>
);

const StatusSection: React.FC<{
    driver: string;
    workTimeStatusIconClass: string;
    workTimeTypeName: string;
}> = ({
    driver,
    workTimeStatusIconClass,
    workTimeTypeName
}) => {
    return (
        <Grid container>
            { driver && (
                <Grid item xs={12} container alignItems="center">
                    { workTimeTypeName && (
                        <Grid item xs={12}>
                            <CircleIcon className={workTimeStatusIconClass} sx={{ display: "inline-block" }} />
                            <BoldText className={workTimeStatusIconClass} sx={{ marginLeft: 1 }}>
                                { workTimeTypeName }
                            </BoldText>
                        </Grid>
                    )}
                    <Grid item>
                        <Box sx={{ marginRight: 1 }}>
                            <LocalShippingIcon />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Text> { driver } </Text>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const DetailsSection: React.FC<{
    employeeId: string;
    timeSlotDuration: string;
    workTimeStatusIconClass: string;
}> = ({
    employeeId,
    timeSlotDuration,
    workTimeStatusIconClass
}) => (
    <Grid container sx={{ textAlign: "right" }}>
        { timeSlotDuration && (
            <Grid item xs={12}>
                <BoldText className={workTimeStatusIconClass} sx={{ display: "inline-block", fontWeight: "bold" }}>
                    { timeSlotDuration }
                </BoldText>
            </Grid>
        )}
        <Grid item xs={12}>
            <WorklogPopover employeeId={employeeId} />
        </Grid>
    </Grid>
);

const WorklogPopover: React.FC<{ employeeId: string }> = ({ employeeId }) => {
    const popoverAnchor = useRef(null);
    const [openedPopover, setOpenedPopover] = useState(false);
    const handlePopoverOpen = () => setOpenedPopover(true);
    const handlePopoverClose = () => setOpenedPopover(false);

    return (
        <Box sx={{ width: "15px", display: "inline-block", mr: 0.5 }}>
            <Typography
                ref={popoverAnchor}
                aria-owns={openedPopover ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseOver={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                onClick={handlePopoverOpen}
                sx={{ marginRight: 1 }}
            >
                <CalendarMonthIcon fontSize="small" color="primary" />
            </Typography>
            <Popover
                id="mouse-over-popover"
                open={openedPopover}
                anchorEl={popoverAnchor.current}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                slotProps={{
                    paper: {
                        sx: { pointerEvents: "auto !important" }, // paper properly opens and closes on hover
                        onClick: (e) => { e.stopPropagation(); } // prevent click event from propagating to the parent
                    }
                }}
                sx={{ pointerEvents: "none" }}
            >
                <Box sx={{ p: 0 }}>
                    <WorklogMain employeeId={employeeId} />
                </Box>
            </Popover>
        </Box>
    );
};

export default EmployeeCardContent;