import { Base } from "../../framework/base";
import { IVehicleGroupEditItem, VehicleGroupEditItem } from "./vehicleGroupEditItem";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";

export interface IVehicleGroupEdit {
    vehicleGroup: IVehicleGroupEditItem;
    vehicles: IVehicleItem[];
}

export class VehicleGroupEdit implements IVehicleGroupEdit {
    vehicleGroup: VehicleGroupEditItem;
    vehicles: VehicleItem[];

    constructor();
    constructor(obj: IVehicleGroupEdit);
    constructor(obj?: any) {
        this.vehicleGroup = new VehicleGroupEditItem(obj ? obj.vehicleGroup : null);
        this.vehicles = Base.getTypedArray<VehicleItem>(obj ? obj.vehicles : null, VehicleItem);
    }
}
