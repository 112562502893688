import React, { useMemo } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import Typography from "@mui/material/Typography";
import {
    GRID_AGGREGATION_FUNCTIONS,
    GridAggregationFunction,
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-premium";

import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { useTranslation } from "react-i18next";
import { VehicleEventType } from "../../../models/vehicle/vehicleEventInfo";
import { MuiDataGrid } from "../../framework/muiDataGrid";
import { VehicleEventData } from "../../../hooks/reporting/useVehicleReportData";
import { Base } from "../../../framework/base";
import { Translations } from "../../../models/translations";

export interface GridRow {
    id: string;
    label: string;
    distance: number;
    fuel: number;
    consumption: number;
}

interface VehicleReportGridProps {
    vehicles: VehicleListItemDto[];
    eventData: VehicleEventData;
}

const calculateAvgConsumption = (distance: number, fuel: number) =>
    (fuel / distance) * 100;

const avgConsumption: GridAggregationFunction<
    { distance: number; fuel: number },
    number
> = {
    label: "avgConsumption",
    getCellValue: ({ row }) => ({ distance: row.distance, fuel: row.fuel }),
    apply: ({ values }) => {
        let distance = 0;
        let fuel = 0;
        values.forEach((value) => {
            if (value) {
                fuel += value.fuel;
                distance += value.distance;
            }
        });
        return calculateAvgConsumption(distance, fuel);
    },
    columnTypes: ["number"],
};

export const VehicleReportGrid = ({
    vehicles,
    eventData,
}: VehicleReportGridProps) => {
    const { t } = useTranslation();

    const gridRows: GridRow[] = useMemo(
        () =>
            Base.getUniqueStringItems(
                Object.values(eventData).flatMap((e) =>
                    e.map((d) => d.vehicleId)
                )
            ).map((vehicleId) => {
                const vehicle = vehicles?.find((v) => v.id === vehicleId);
                const distance = eventData[VehicleEventType.Distance].find(
                    (e) => e.vehicleId === vehicleId
                )?.value;
                const fuel = eventData[VehicleEventType.TotalFuel].find(
                    (e) => e.vehicleId === vehicleId
                )?.value;

                return {
                    id: vehicleId,
                    label: `${vehicle?.registerNumber} - ${vehicle?.brand}`,
                    distance,
                    fuel,
                    consumption: calculateAvgConsumption(distance, fuel),
                };
            }),
        [vehicles, eventData]
    );

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            {
                field: "label",
                headerName: t("reporting.fleet"),
                description: t("reporting.fleet"),
                groupable: false,
                type: "string",
                minWidth: 100,
                flex: 1,
                renderCell: (params: GridRenderCellParams<GridRow>) => {
                    if (params?.rowNode?.type === "pinnedRow") {
                        return (
                            <div className="font-weight-bold">
                                {Translations.Total}
                            </div>
                        );
                    }

                    return params.formattedValue;
                },
            },
            {
                field: "distance",
                headerName: t("reporting.distance"),
                description: t("reporting.distance"),
                groupable: false,
                type: "number",
                availableAggregationFunctions: ["sum"],
                minWidth: 100,
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(1, "fi-Fi");
                },
            },
            {
                field: "fuel",
                headerName: t("reporting.consumption"),
                description: t("reporting.consumption"),
                groupable: false,
                type: "number",
                availableAggregationFunctions: ["sum"],
                minWidth: 100,
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(1, "fi-Fi");
                },
            },
            {
                field: "consumption",
                headerName: t("reporting.avgConsumption"),
                description: t("reporting.avgConsumption"),
                groupable: false,
                type: "number",
                availableAggregationFunctions: ["avgConsumption"],
                minWidth: 100,
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(1, "fi-Fi");
                },
            },
        ],
        []
    );

    const aggregationModel = useMemo(
        () =>
            Object.fromEntries([
                ...gridDef
                    .filter((c) => c.type === "number")
                    .map((c) => [c.field, c.availableAggregationFunctions[0]]),
            ]),
        [gridDef]
    );

    return (
        <div style={{ paddingTop: "1rem" }} id="vehicles-container">
            <Typography pl={1} variant="h3">
                {t("reporting.fleet")}
            </Typography>
            <MuiDataGrid
                density="compact"
                disableToolbar
                disableColumnReorder
                disableColumnMenu
                hideFooter
                rowSelection={false}
                columns={gridDef}
                rows={gridRows}
                aggregationModel={aggregationModel}
                aggregationFunctions={{
                    ...GRID_AGGREGATION_FUNCTIONS,
                    avgConsumption,
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "vehicleId", sort: "asc" }],
                    },
                }}
            />
        </div>
    );
};
