export enum VehicleEventType {
    Distance,
    TotalFuel,
}

export interface VehicleEventInfo {
    carId: string;
    vehicleId: string;
    type: VehicleEventType;
    value: number;
    timestamp: string;
}

export interface VehicleEventQueryParams {
    vehicleIds: string[];
    windowStartTime: string;
    windowEndTime: string;
}
