import React, { useEffect, useMemo, useState } from "react";
import {
    getWorkTimeTypeColor,
    WorkTimeType,
} from "../../models/workShitTimeSlot/workTimeType";
import { useFetch } from "../../hooks/useFetch";
import { getWorkTimeTypes } from "../../services/workTimeBetaService";
import Box from "@mui/material/Box";
import { MuiSwitch } from "../framework/muiSwitch";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    setMapFilterNoWorkTime,
    setMapFilterWorkTimeTypeIds,
    toggleMapFilterWorkTimeTypeId,
    setMapFilterWorking,
    setMapFilterNoWork,
} from "../../store/monitorViewSlice";
import { Base } from "../../framework/base";

interface MonitorViewFiltersProps {
    countByWorkTimeType: { [id: string]: number };
}

export const MonitorViewFilters = ({
    countByWorkTimeType,
}: MonitorViewFiltersProps) => {
    const [workTimeTypes, setWorkTimeTypes] = useState<WorkTimeType[]>();
    const { t } = useTranslation();

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const working = useAppSelector(
        (state) => state.monitorView.filters.working
    );

    const noWork = useAppSelector(
        (state) => state.monitorView.filters.noWork
    );

    const dispatch = useAppDispatch();

    useFetch(
        (signal) => getWorkTimeTypes(signal),
        {
            onSuccess: (res) => setWorkTimeTypes(res),
            withLoadingIndicator: false,
        },
        []
    );

    const typeFilters = useMemo(
        () =>
            workTimeTypes
                ? [
                    ...workTimeTypes.sort((a, b) =>
                        Base.stringCompare(a.name, b.name)
                    )
                ]
                : null,
        [workTimeTypes]
    );

    useEffect(() => {
        if (!selectedWorkTimeTypeIds && !!typeFilters) {
            dispatch(
                setMapFilterWorkTimeTypeIds(
                    typeFilters.map((f) => f.workTimeTypeId)
                )
            );
        }
    }, [selectedWorkTimeTypeIds, typeFilters]);

    const handleWorkingToggle = (checked: boolean) => {
        dispatch(setMapFilterWorking(checked));
        if (checked) {
            const workTimeTypeIds = typeFilters
                .filter((f) => f.workTimeTypeId !== null)
                .map((f) => f.workTimeTypeId);
            dispatch(setMapFilterWorkTimeTypeIds(workTimeTypeIds));
        } else {
            dispatch(setMapFilterWorkTimeTypeIds([]));
        }
    };

    const handleNoWorkToggle = (checked: boolean) => {
        dispatch(setMapFilterNoWork(checked));
        if (checked) {
            dispatch(setMapFilterNoWorkTime(true));
        } else {
            dispatch(setMapFilterNoWorkTime(false));
        }
    };

    return (
        <Box>
            {!workTimeTypes || !selectedWorkTimeTypeIds ? (
                <CircularProgress />
            ) : (
                <>
                    {typeFilters.map((w) => (
                        <MuiSwitch
                            key={w.workTimeTypeId}
                            checked={selectedWorkTimeTypeIds.includes(
                                w.workTimeTypeId
                            )}
                            label={`${w.name} (${
                                countByWorkTimeType[w.workTimeTypeId] ?? 0
                            })`}
                            color={getWorkTimeTypeColor(w.type)}
                            onChange={(_, checked) =>
                                dispatch(
                                    toggleMapFilterWorkTimeTypeId({
                                        id: w.workTimeTypeId,
                                        checked,
                                    })
                                )
                            }
                            size="small"
                            labelSlotProps={{ typography: { fontSize: "0.7rem" } }}
                        />
                    ))}
                    <MuiSwitch
                        checked={working}
                        label={`${t("map.working")} (${countByWorkTimeType["work"] ?? 0})`}
                        color={"brown"}
                        onChange={(_, checked) => handleWorkingToggle(checked)}
                        size="small"
                        labelSlotProps={{ typography: { fontSize: "0.7rem" } }}
                    />
                    <MuiSwitch
                        checked={noWork}
                        label={`${t("map.noWork")} (${countByWorkTimeType["noWork"] ?? 0})`}
                        color={getWorkTimeTypeColor(null)}
                        onChange={(_, checked) => handleNoWorkToggle(checked)}
                        size="small"
                        labelSlotProps={{ typography: { fontSize: "0.7rem" } }}
                    />
                </>
            )}
        </Box>
    );
};