import { ButtonProps } from "@mui/material";
import Alert from "@mui/material/Alert";
import { alpha, styled } from "@mui/material/styles";
import { DataGridPremium, DataGridPremiumProps, GridLocaleText, fiFI, gridClasses } from "@mui/x-data-grid-premium";
import * as React from "react";
import { memo } from "react";
import { Translations } from "../../models/translations";
import { CustomToolbar } from "./customToolbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const ROW_SELECT_OPACITY = 0.8;

export const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.lightGrayBlue.light,
    },
    [`& .${gridClasses.cell}:focus`]: {
        outline: "none",
    },
    [`& .${gridClasses.row}`]: {
        "&:hover, &.Mui-hovered": {
            backgroundColor: alpha(
                theme.palette.green.extraLight,
                ROW_SELECT_OPACITY - 0.55
            ),
            "@media (hover: none)": {
                backgroundColor: "none",
            },
        },
        "&.Mui-selected": {
            backgroundColor: alpha(
                theme.palette.green.extraLight,
                ROW_SELECT_OPACITY
            ),
            "&:hover, &.Mui-hovered": {
                backgroundColor: alpha(
                    theme.palette.green.extraLight,
                    ROW_SELECT_OPACITY + 0.15
                ),
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: alpha(
                        theme.palette.green.extraLight,
                        ROW_SELECT_OPACITY
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.columnHeaders}`]: {
        backgroundColor: theme.palette.primary.dark,
    },
    [`& .${gridClasses.columnHeader}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: "white"
    },
    [`& .${gridClasses.columnHeader}:focus`]: {
        outline: "none"
    },
    [`& .${gridClasses.filterIcon}`]: {
        color: "white"
    },
    [`& .${gridClasses.sortIcon}`]: {
        color: "white"
    },
    [`& .${gridClasses.menuIconButton}`]: {
        color: "white"
    },
    [`& .${gridClasses.iconSeparator}`]: {
        color: theme.palette.gray.main,
        // override cursor: pointer, because this is non-clickable when disableColumnResize=true
        cursor: "default"
    },
}));

interface IMuiDataGridProps {
  disableToolbar?: boolean;
  title?: string;
  customToolbarButtons?: ButtonProps[];
}

type MuiDataGridProps = IMuiDataGridProps & DataGridPremiumProps;

// toolbarColumn ylikirjoitetaan tyhjällä merkkijonolla, jotta näytetään pelkkä ikoni
const defaultLocale: Partial<GridLocaleText> = { toolbarColumns: "", toolbarFilters: "" };
const fiLocale: Partial<GridLocaleText> = { ...fiFI.components.MuiDataGrid.defaultProps.localeText, toolbarColumns: "", toolbarFilters: "" };
const DetailPanelExpandIcon = () => <KeyboardArrowRightIcon color="primary" />;
const DetailPanelCollapseIcon = () => <KeyboardArrowDownIcon color="primary" />;

export const MuiDataGrid = memo((props: MuiDataGridProps) => {
    const [filterButtonEl, setFilterButtonEl] = React.useState(null);
    return (
        <StripedDataGrid
            {...props}
            disableColumnResize
            localeText={appConfig.culture === "fi" ? fiLocale : defaultLocale}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            slots={{
                noRowsOverlay: () => (
                    <Alert severity="info" sx={{ p: 2 }}>
                        {Translations.NoRows}
                    </Alert>
                ),
                toolbar: props.disableToolbar ? null : CustomToolbar,
                detailPanelExpandIcon: DetailPanelExpandIcon,
                detailPanelCollapseIcon: DetailPanelCollapseIcon,
            }}
            slotProps={{
                panel: {
                    anchorEl: filterButtonEl,
                    placement: "bottom-end",
                },
                toolbar: {
                    setFilterButtonEl,
                    title: props.title,
                    customToolbarButtons: props.customToolbarButtons,
                },
                columnMenu: {
                    slots: {
                        columnMenuAggregationItem: null,
                        columnMenuPinningItem: null,
                        columnMenuSortItem: null,
                        columnMenuGroupingItem: null,
                    },
                },
            }}
        />
    );
});
