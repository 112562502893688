import React from "react";
import { VehicleReportGrid } from "./vehicleReportGrid";
import {
    useVehicleReportData,
    VehicleReportData,
    VehicleReportOptionsMapping,
} from "../../../hooks/reporting/useVehicleReportData";
import { MainLayout } from "../../layout/mainLayout";
import { ReportingFilters } from "../reportingFiltersNew";
import { LoadingIndicator } from "../../framework/loadingIndicatorNew";

const dataInitialized = (data: VehicleReportData) =>
    !!data.vehicles && !!data.eventData;

export const VehiclesMain = () => {
    const data = useVehicleReportData(Object.keys(VehicleReportOptionsMapping));
    const initialized = dataInitialized(data);

    return (
        <MainLayout
            noContainer
            className="fluid"
            topComponent={
                <ReportingFilters
                    vehicles={data.presentVehicles}
                    hideEmployeeGroup
                />
            }
        >
            <>
                <LoadingIndicator loading={data.loading || !initialized} />
                {initialized && <VehicleReportGrid {...data} />}
            </>
        </MainLayout>
    );
};
