import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import {
    GridColDef,
    GridRowParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-premium";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";
import { SalaryItem } from "../../models/workHourSalary/salaryItem";
import * as salaryService from "../../services/salaryService";
import { showApiError } from "../framework/formUtils";
import { MuiDataGrid } from "../framework/muiDataGrid";
import { useTranslation } from "react-i18next";
import { TableDef, TableWrapper } from "../framework/tableWrapper";
import MuiRadioGroup from "../framework/muiRadioGroup";
import Grid from "@mui/material/Unstable_Grid2";

enum GroupOption {
    WorkHourSalary = "workHourSalaryId",
    CostCenter = "costCenterId",
    Vehicle = "vehicleId",
    Date = "date",
}

enum RadioGroupOption {
    None = "none",
    Date = "date",
    SalaryPeriod = "salaryPeriod",
}

interface SalaryTransferDialogProps {
    onClose: () => void;
    open: boolean;
    salaries: SalaryItem[];
}

function amountToNumber(value: number, measureUnit: string) {
    if (AppUtils.isTimeUnit(measureUnit)) {
        return (value / 60);
    }
    return value;
}

function amountToString(value: number, measureUnit: string, decimals?: number) {
    return value.toLocaleFixed(
        decimals ?? AppUtils.isTimeUnit(measureUnit) ? 2 : 0
    );
}


export const SalaryTransferDialog = (props: SalaryTransferDialogProps) => {
    const { salaries } = props;
    const [targetErps, setTargetErps] = useState<string[]>([]);
    const [erpMenuAnchor, setErpMenuAnchor] = useState<null | HTMLElement>(
        null
    );
    const [sendingSalary, setSendingSalary] = useState(false);
    const [groupOptions, setGroupOptions] = useState<string[]>([]);
    const { t } = useTranslation();

    const disaggregationOptions = useMemo(
        () => [
            { value: GroupOption.CostCenter, label: t("workTime.costCenter") },
            { value: GroupOption.Vehicle, label: t("vehicle.vehicle") },
        ],
        []
    );

    const radioGroupOptions = useMemo(
        () =>
            Object.values(RadioGroupOption).map((o) => ({
                value: o,
                label: t(`salaryTransfer.groupingOption.${o}`),
            })),
        []
    );

    useEffect(() => {
        void salaryService.targetErps().then((res) => {
            setTargetErps(res);
        });
    }, []);

    const gridDef: GridColDef<SalaryItem>[] = useMemo(
        () => [
            {
                headerName: "Päiväys",
                field: "date",
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams<string>) =>
                    Base.dayjsToDateStr(params.value),
            },
            {
                headerName: "Työntekijä",
                field: "employeeName",
                flex: 1,
            },
            {
                headerName: "Kommentti",
                field: "comment",
                flex: 1,
            },
            {
                headerName: "Siirretty",
                field: "transferDate",
                flex: 1,
                valueFormatter: (params: GridValueFormatterParams<string>) =>
                    !!params.value
                        ? Base.dayjsDateToDateTimeStr(params.value)
                        : "",
            },
        ],
        []
    );

    const [opts, setOpts] = useState({
        setToTransferred: true,
        includeTransferred: true,
    });

    const selectedSalaries = useMemo(
        () =>
            opts.includeTransferred
                ? salaries
                : salaries.filter((s) => !s.transferDate),
        [opts.includeTransferred, salaries]
    );

    const handleOptsChange = (newOpts: Partial<typeof opts>) => {
        setOpts({ ...opts, ...newOpts });
    };

    const generateSaveData = () => {
        const saveData = new SaveData();
        saveData.append("setToTransferred", opts.setToTransferred.toString());
        saveData.append("salaryPeriodId", selectedSalaries[0].salaryPeriodId);
        saveData.append(
            "ids",
            JSON.stringify(selectedSalaries.map((s) => s.salaryId))
        );
        saveData.append(
            "groupOptions",
            JSON.stringify(groupOptions.map((opt) => opt))
        );
        return saveData;
    };

    const sendToErp = (targetErp: string) => {
        const saveData = generateSaveData();
        setSendingSalary(true);
        setErpMenuAnchor(null);
        AppUtils.callService(() =>
            salaryService.sendSalary(saveData.formData, targetErp)
        )
            .then(() => {
                // TODO refresh view
            })
            .catch((err) => {
                showApiError(err);
            })
            .finally(() => {
                setSendingSalary(false);
            });
    };

    const downloadSalaryFile = () => {
        const saveData = generateSaveData();
        setSendingSalary(true);
        AppUtils.callService(() =>
            salaryService.getSalaryFile(saveData.formData)
        )
            .then(() => {
                // TODO refresh view
            })
            .catch((err) => {
                showApiError(err);
            })
            .finally(() => {
                setSendingSalary(false);
            });
    };

    const getDetailPanelContent = useCallback(
        (params: GridRowParams) => (
            <SalaryDetailsTable
                row={params.row}
                groupOptions={groupOptions}
            />
        ),
        [groupOptions]
    );

    return (
        <Dialog open={props.open} fullWidth maxWidth="lg">
            <DialogTitle>
                Siirrä maksettavaksi
                <IconButton
                    onClick={() => props.onClose()}
                    sx={{
                        position: "absolute",
                        right: 16,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <MuiDataGrid
                    rows={selectedSalaries}
                    columns={gridDef}
                    getRowId={(row) => row.salaryId}
                    density="compact"
                    disableToolbar
                    hideFooter
                    disableColumnResize
                    disableColumnMenu
                    rowSelection={false}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={() => "auto"}
                    sx={{
                        width: "100%",
                        // This is needed to make sticky positioning work for the detail panel
                        "& .MuiDataGrid-detailPanel": {
                            overflow: "visible",
                        },
                        // Needed to display "no rows" overlay correctly
                        ".MuiDataGrid-overlayWrapper": {
                            height: "auto !important",
                        },
                        ".MuiDataGrid-overlayWrapperInner": {
                            height: "auto !important",
                        },
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ mx: 2, mb: 1 }}>
                <Grid container>
                    <Grid xs={6}>
                        <FormGroup row>
                            <MuiRadioGroup
                                label={t("salaryTransfer.grouping")}
                                options={radioGroupOptions}
                                defaultValue={RadioGroupOption.None}
                                onChange={(val) =>
                                    setGroupOptions(
                                        val === RadioGroupOption.None
                                            ? [GroupOption.WorkHourSalary]
                                            : val === RadioGroupOption.Date
                                            ? [GroupOption.Date]
                                            : []
                                    )
                                }
                            />
                        </FormGroup>
                    </Grid>
                    <Grid container xs={6} sx={{ alignItems: "end" }}>
                        <Grid xs={12}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={opts.setToTransferred}
                                            onChange={(val) =>
                                                handleOptsChange({
                                                    setToTransferred:
                                                        val.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Merkitse palkat Siirretty-tilaan"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={opts.includeTransferred}
                                            onChange={(val) =>
                                                handleOptsChange({
                                                    includeTransferred:
                                                        val.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label="Sisällytä jo siirretyt palkat"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                            {!groupOptions.includes(
                                GroupOption.WorkHourSalary
                            ) ? (
                                <FormGroup row sx={{ mb: 2, mt: 1 }}>
                                    <FormLabel
                                        component="legend"
                                        sx={{ mb: 0 }}
                                    >
                                        {t("salaryTransfer.disaggregation")}
                                    </FormLabel>
                                    {disaggregationOptions.map((o) => (
                                        <FormControlLabel
                                            key={o.value}
                                            control={
                                                <Checkbox
                                                    checked={groupOptions.includes(
                                                        o.value
                                                    )}
                                                    onChange={(_, checked) =>
                                                        setGroupOptions(
                                                            checked
                                                                ? [
                                                                      ...groupOptions,
                                                                      o.value,
                                                                  ]
                                                                : groupOptions.filter(
                                                                      (opt) =>
                                                                          opt !==
                                                                          o.value
                                                                  )
                                                        )
                                                    }
                                                />
                                            }
                                            label={o.label}
                                        />
                                    ))}
                                </FormGroup>
                            ) : null}
                        </Grid>
                        <Grid xs={12}>
                            <FormGroup row sx={{ alignItems: "start" }}>
                                <Button
                                    variant="contained"
                                    onClick={downloadSalaryFile}
                                    disabled={
                                        sendingSalary ||
                                        selectedSalaries.length === 0
                                    }
                                    sx={{ mb: 0.5, mr: 2 }}
                                >
                                    Siirtotiedostoon
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={(evt) =>
                                        setErpMenuAnchor(evt.currentTarget)
                                    }
                                    disabled={
                                        sendingSalary ||
                                        selectedSalaries.length === 0
                                    }
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    palkanmaksujärjestelmään
                                </Button>
                                <Menu
                                    anchorEl={erpMenuAnchor}
                                    open={Boolean(erpMenuAnchor)}
                                    onClose={() => setErpMenuAnchor(null)}
                                >
                                    {targetErps.map((erp) => (
                                        <MenuItem
                                            key={erp}
                                            onClick={() => sendToErp(erp)}
                                        >
                                            {erp}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

interface SalaryDetailsProps {
    row: SalaryItem;
    groupOptions: string[];
}

interface DetailsTableRow {
    date: string;
    amount: number;
    salaryRowTypeCode: string;
    salaryRowTypeName: string;
    measureUnit: string;
    decimals: number;
    costCenterName: string;
    vehicleName: string;
}

const SalaryDetailsTable = ({ row, groupOptions }: SalaryDetailsProps) => {
    const { t } = useTranslation();

    const tableDef: TableDef<any> = useMemo(
        () => ({
            columns: [
                groupOptions.includes(GroupOption.Date) ||
                groupOptions.includes(GroupOption.WorkHourSalary)
                    ? {
                          id: "date",
                          accessor: "date",
                          label: t("common.date"),
                          renderCell(val: string) {
                              return Base.dayjsToDateStr(val);
                          },
                      }
                    : null,
                {
                    label: t("workTime.amount"),
                    accessor: "amount",
                    renderCell: (_, row: DetailsTableRow) =>
                        amountToString(
                            row.amount,
                            row.measureUnit,
                            row.decimals
                        ),
                },
                {
                    accessor: "salaryRowTypeCode",
                    label: t("workTime.salaryRowType"),
                },
                { accessor: "salaryRowTypeName" },
                groupOptions.includes(GroupOption.CostCenter) ||
                groupOptions.includes(GroupOption.WorkHourSalary)
                    ? {
                          accessor: "costCenterName",
                          label: t("workTime.costCenter"),
                      }
                    : null,
                groupOptions.includes(GroupOption.Vehicle) ||
                groupOptions.includes(GroupOption.WorkHourSalary)
                    ? { accessor: "vehicleName", label: t("vehicle.vehicle") }
                    : null,
            ].filter(Boolean),
        }),
        [groupOptions]
    );

    // Group data and create rows
    const rows: DetailsTableRow[] = useMemo(
        () =>
            _map(
                _groupBy(row.workHourSalaries, (whs) =>
                    [
                        whs.salaryRowTypeId,
                        ...groupOptions.map((o) => whs[o]),
                    ].join("_")
                ),
                (value, key) => {
                    const whs = value[0];

                    return {
                        id: key,
                        amount: amountToNumber(
                            value.reduce((acc, curr) => acc + curr.amount, 0),
                            whs.measureUnit
                        ),
                        salaryRowTypeCode: whs.salaryRowTypeCode,
                        salaryRowTypeName: `${whs.salaryRowTypeName} (${whs.measureUnit})`,
                        decimals: whs.decimals,
                        measureUnit: whs.measureUnit,
                        costCenterName: whs.costCenterName,
                        vehicleName: whs.vehicleName,
                        date: whs.date,
                    };
                }
            ).sort((a, b) => Base.stringCompare(a.date, b.date)),
        [row.workHourSalaries, groupOptions]
    );

    return (
        <Box
            sx={{
                pb: 2,
                px: 2,
                width: "fit-content",
                position: "sticky",
                left: 0,
            }}
        >
            <TableWrapper
                tableDef={tableDef as TableDef<DetailsTableRow>}
                data={rows}
                disablePagination
            />
        </Box>
    );
};
