import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import { customHistory } from "../../framework/customHistory";
import { generatePath, useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Translations } from "../../models/translations";
import { AppUtils } from "../../models/common/appUtils";
import Tooltip from "@mui/material/Tooltip";
import { Base } from "../../framework/base";
import { useSelectPaidWorkHoursAmountByDate } from "../../hooks/workTime/useSelectWorkHours";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { useSelectCalendarDay } from "../../hooks/useCalendar";
import { getDayBookingItems } from "../../services/dayBookingService";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { getWorkShifts } from "../../services/workShiftService";
import { WorkShiftDto } from "../../models/workShift/workShift";

interface IWorkTimeDetailsCalendarProps {
    path: string;
    reloadKey: number;
}

const durationStrLengthThreshold = 5;

export const WorkTimeDetailsCalendar = ({
    path,
    reloadKey,
}: IWorkTimeDetailsCalendarProps) => {
    const { employeeId, date, tab } = useParams<IWorkTimeDetailsPage>();
    const [dayBookingItems, setDayBookingItems] = useState<DayBookingItemBeta[]>();
    const [workShifts, setWorkShifts] = useState<WorkShiftDto[]>();

    const handleMonthChange = (date: Date) => {
        customHistory.push(
            generatePath(path, {
                employeeId,
                date: Base.dayjsToJsonDate(date),
                tab,
            })
        );
    };

    const startDate = Base.dayjsToJsonDate(
        Base.dateStartOf(Base.dateStartOf(date, "month"), "week")
    );
    const endDate = Base.dayjsToJsonDate(
        Base.dateEndOf(Base.dateEndOf(date, "month"), "week")
    );

    useAsyncThunkAction(
        () =>
            fetchCalendarDays({
                startDate,
                endDate,
            }),
        { onError: () => null },
        [startDate, endDate]
    );

    const loadDayBookings = async(startDate: string, endDate: string, signal: AbortSignal) => {
        try {
            const result = await getDayBookingItems(
                startDate,
                endDate,
                employeeId,
                signal
            );
            setDayBookingItems(result);
        } catch (error) {
            if (error.name !== "AbortError") {
                console.log(error);
            }
        }
    };

    const loadWorkShiftTimeSlots = async(startDate: string, endDate: string, signal: AbortSignal) => {
        try {
            const result = await getWorkShifts(
                employeeId,
                startDate,
                endDate,
                true,
                signal
            );
            setWorkShifts(result);
        } catch (error) {
            if (error.name !== "AbortError") {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        void loadDayBookings(startDate, endDate, signal);
        void loadWorkShiftTimeSlots(startDate, endDate, signal)

        return () => {
            controller.abort();
        };
    }, [startDate, endDate, employeeId, reloadKey]);

    const allEntriesLocked = (date: Date) => {
        const dateString = Base.dayjsToJsonDate(date);
        let allDayBookingsLocked = false;
        let hasWorkEntries = false;
        let hasDayBookings = false;

        const dayShifts =
            workShifts?.filter((s) => s.effectiveDate === dateString) ?? [];

        if (dayShifts.length > 0) {
            hasWorkEntries = true;
        }

        const allWorkTimesLocked =
            hasWorkEntries &&
            dayShifts.every((s) =>
                s.workShiftTimeSlots.every((slot) => slot.state === 2)
            );

        let dayBookings = dayBookingItems?.filter((db) => db.day <= dateString && db.endDay >= dateString);
        let lockedDaybookings = dayBookingItems?.filter((db) => db.day <= dateString && db.endDay >= dateString && db.state == 1)

        if (dayBookings?.length > 0) {
            hasDayBookings = true;
        }

        if (dayBookings?.length > 0 && dayBookings?.length === lockedDaybookings?.length) {
            allDayBookingsLocked = true;
        }

        return allWorkTimesLocked && !hasDayBookings ||
            allWorkTimesLocked && allDayBookingsLocked ||
            allDayBookingsLocked && !hasWorkEntries;
    };

    const renderDayContents = (day: number, date: Date) => {
        return <DayContents
            day={day}
            date={date}
            employeeId={employeeId}
            dayBookingItems={dayBookingItems}
        />;
    };

    return (
        <div className="worktime-details-datepicker-container">
            <DatePicker
                calendarClassName="worktime-details-datepicker"
                dateFormat="dd.MM.yyyy"
                locale={fi} // TODO: get from appConfig
                selected={new Date(date)}
                onChange={(value) => {
                    customHistory.push(
                        generatePath(path, {
                            employeeId,
                            date: Base.dayjsToJsonDate(value),
                            tab,
                        })
                    );
                }}
                weekLabel={Translations.AbrWeek}
                showWeekNumbers
                inline
                renderDayContents={renderDayContents}
                onMonthChange={handleMonthChange}
                dayClassName={(date) => allEntriesLocked(date) ? "all-entries-locked" : null }
            />
        </div>
    );
};

interface DayContentsProps {
    day: number;
    date: Date;
    employeeId: string;
    dayBookingItems?: DayBookingItemBeta[];
}

const DayContents = (props: DayContentsProps) => {
    const [open, setOpen] = React.useState(false);
    const { day, date, employeeId, dayBookingItems } = props;
    const dateString = Base.dayjsToJsonDate(date);
    const minutes = useSelectPaidWorkHoursAmountByDate(
        employeeId,
        dateString,
        dateString
    );
    const totalStr = AppUtils.getDurationStrByDurationMinShort(minutes);
    const calendarDay = useSelectCalendarDay(dateString);

    const hasDayBookings = dayBookingItems?.some(
        (item) => item.day <= dateString && item.endDay >= dateString
    );

    const dayProps = {
        title: calendarDay?.description,
        style: { color: calendarDay?.isHoliday ? "red" : "inherit" }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (totalStr.length > durationStrLengthThreshold) {
            setOpen(true);
        }
    };

    return (
        <>
            <span {...dayProps}>
                <div className={hasDayBookings ? "has-daybookings" : null}>
                    {day}
                </div>
                {minutes > 0 && (
                    <Tooltip
                        title={totalStr}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        <p className="worktime-details-datepicker-hours text-truncate px-1">
                            {totalStr}
                        </p>
                    </Tooltip>
                )}
            </span>
        </>
    );
};
