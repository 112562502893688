import * as React from "react";
import { useEffect, useRef } from "react";
import { WorkTimeDetailsForm } from "./workTimeDetailsForm";
import { WorkTimeDetailsDayBookingForm } from "./workTimeDetailsDayBookingForm";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { alpha, useTheme } from "@mui/material";
import { Translations } from "../../models/translations";
import { useSelectWorkShiftTimeSlotById } from "../../hooks/workTime/useSelectWorkShiftTimeSlots";
import { useAppSelector } from "../../framework/customStore";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import { HourBookingCategory } from "../../models/workTime/workHoursItem";
import {
    RouteComponentProps,
    generatePath,
    useLocation,
    useParams,
} from "react-router-dom";
import { LinkTabs } from "../framework/linkTabs";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { customHistory } from "../../framework/customHistory";
import { useReload } from "../../hooks";
import { ISalaryRowType } from "../../models/workShitTimeSlot/salaryRowType";

interface Props {
  afterSave: () => void;
  editDayBookingItem: DayBookingItemBeta;
  setEditDayBookingItem: React.Dispatch<React.SetStateAction<DayBookingItemBeta>>;
  open?: boolean;
  workShiftTimeSlotItems: WorkShiftTimeSlotItem[];
  salaryRowTypes: ISalaryRowType[];
}

export enum Tab {
    HourBooking = "hourbooking",
    Custom = "other",
    DayBooking = "daybooking"
}

export const WorkTimeDetailsFormTab: React.FC<Props & RouteComponentProps> = ({
    afterSave,
    editDayBookingItem,
    setEditDayBookingItem,
    open,
    workShiftTimeSlotItems,
    salaryRowTypes,
    match,
}) => {
    const { employeeId, date, workShiftId } = useParams<IWorkTimeDetailsPage>();
    const { path } = match;
    const [expanded, setExpanded] = React.useState(!!open);
    const [resetFormKey, resetForm] = useReload();
    const theme = useTheme();
    const accordionRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();
    const location = useLocation();

    const selectedWorkTimeId = useAppSelector((state) => state.workShiftTimeSlot.editId);
    const editItem = useSelectWorkShiftTimeSlotById(selectedWorkTimeId);

    useEffect(() => {
        setExpanded(!!open);
    }, [open]);

    const handleTabsChange = (event: React.SyntheticEvent) => {
        setExpanded(true);
        event.stopPropagation();
    };

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const getPath = (tab: Tab) =>
        generatePath(path, {
            employeeId,
            date,
            tab,
            workShiftId,
        });

    useEffect(() => {
        if (editItem) {
            customHistory.push(
                getPath(editItem.isCustomType ? Tab.Custom : Tab.HourBooking)
            );

            setExpanded(true);
            setTimeout(() => {accordionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });}, 300);
        }
    }, [editItem]);

    useEffect(() => {
        if (editDayBookingItem) {
            customHistory.push(getPath(Tab.DayBooking));

            setExpanded(true);
            setTimeout(() => {accordionRef.current.scrollIntoView({ behavior: "smooth", block: "center" });}, 300);
        }
    }, [editDayBookingItem]);

    const tabs = [
        {
            value: Tab.HourBooking,
            label: Translations.HourBooking,
            to: getPath(Tab.HourBooking),
            children: (
                <WorkTimeDetailsForm
                    resetFormKey={resetFormKey}
                    editItem={editItem}
                    workShiftTimeSlotItems={workShiftTimeSlotItems}
                    afterSave={afterSave}
                    salaryRowTypeCategories={[
                        HourBookingCategory.CustomNormalType,
                        HourBookingCategory.CustomAddition,
                    ]}
                    salaryRowTypes={salaryRowTypes}
                />
            ),
        },
        {
            value: Tab.Custom,
            label: t("workTime.customTypeTabLabel"),
            to: getPath(Tab.Custom),
            children: (
                <WorkTimeDetailsForm
                    resetFormKey={resetFormKey}
                    editItem={editItem}
                    workShiftTimeSlotItems={workShiftTimeSlotItems}
                    afterSave={afterSave}
                    salaryRowTypeCategories={[
                        HourBookingCategory.CustomAnyType,
                    ]}
                    salaryRowTypes={salaryRowTypes}
                />
            ),
        },
        {
            value: Tab.DayBooking,
            label: Translations.DayBooking,
            to: getPath(Tab.DayBooking),
            children: (
                <WorkTimeDetailsDayBookingForm
                    afterSave={afterSave}
                    editDayBookingItem={editDayBookingItem}
                    setEditDayBookingItem={setEditDayBookingItem}
                />
            ),
        },
    ];

    const currentTab = tabs.find(t => location.pathname.match(t.to));

    useEffect(() => {
        if (currentTab && !editItem) {
            resetForm();
        }
    }, [currentTab?.value]);

    return (
        <Accordion
            ref={accordionRef}
            onChange={handleAccordionChange}
            expanded={expanded}
            variant="elevation"
            sx={{ width: "100%", height: "100%" }}
        >
            <AccordionSummary
                expandIcon={<KeyboardArrowDownIcon id="workTime-form-header" />}
                sx={{
                    // Prevent vertical jiggling of the accordion header when expanding
                    "& .MuiAccordionSummary-content.Mui-expanded": {
                        margin: "12px 0",
                    },
                }}
            >
                <LinkTabs
                    tabsOnly
                    tabs={tabs}
                    handleTabsChange={handleTabsChange}
                    TabsProps={{
                        TabIndicatorProps: {
                            style: {
                                backgroundColor: theme.palette.primary.main,
                            },
                        },
                        sx: {
                            "& button": { borderRadius: "5px" },
                            "& button: hover": {
                                backgroundColor: alpha(
                                    theme.palette.primary.main,
                                    0.1
                                ),
                            },
                            "& button.Mui-selected": {
                                color: theme.palette.primary.main,
                            },
                        },
                    }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <LinkTabs panelsOnly tabs={tabs} />
            </AccordionDetails>
        </Accordion>
    );
};
