import * as React from "react";
import { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import { formatTime, WorkTimeDetailsList } from "./workTimeDetailsList";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customHistory } from "../../framework/customHistory";
import MuiSelect from "../framework/muiSelect";
import {
    generatePath,
    Redirect,
    RouteComponentProps,
    useParams,
} from "react-router-dom";
import { WorkHoursPerWeek } from "./workHoursPerWeek";
import { WorkHoursPerSalaryPeriod } from "./workHoursPerSalaryPeriod";
import { WorkTimeDetailsDayBookingList } from "./workTimeDetailsDayBookingList";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { WorkTimeDetailsFormTab } from "./workTimeDetailsFormTab";
import { useReload } from "../../hooks";
import { WorkTimeDetailsCalendar } from "./workTimeDetailsCalendar";
import { useAppDispatch } from "../../framework/customStore";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import {
    fetchWorkTimeTypes,
    setWorkShiftTimeSlots,
} from "../../store/workShiftTimeSlotSlice";
import { Base } from "../../framework/base";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkTimeDetailsMap } from "./workTimeDetailsMap";
import { WorkTimeDetailsLayout } from "../layout/workTimeDetailsLayout";
import { WorkTimeTimeline } from "./workTimeTimeline";
import { getDayBookingItems } from "../../services/dayBookingService";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { calculatedWorkHours, normalWorkHours } from "../../store/workHoursSlice";
import * as dayBookingTypeService from "../../services/dayBookingTypeService";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";
import * as store from "../../framework/customStore";
import { DayAllowanceSelect } from "./dayAllowanceSelect";
import { PrivateRoute } from "../app/privateRoute";
import { getSalaryRowTypes } from "../../services/workTimeBetaService";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { useFetch } from "../../hooks/useFetch";
import { getEmployees } from "../../services/employeeServiceNew";
import { ISalaryRowType } from "../../models/workShitTimeSlot/salaryRowType";
import { FillingHoursPerSalaryPeriod } from "./fillingHoursPerSalaryPeriod";
import { TESCalculationParameters } from "../../models/calculation/TESCalculationParameters";
import { getFunctionSettings } from "../../services/employeeGroupService";
import { showError } from "../storage/storageUtils";
import { HourBookingCategory } from "../../models/workTime/workHoursItem";
import { useTranslation } from "react-i18next";
import { WorkTimeDetailsWorkShiftDateSelect } from "./workTimeDetailsWorkShiftDateSelect";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { getWorkShiftsByTimeRange } from "../../services/workShiftService";
import { showApiError } from "../framework/formUtils";
import { WorkShiftTimeSlotItems } from "../../models/workShitTimeSlot/workShitTimeSlotItems";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";

interface IWorkTimeDetailsMainProps {
    backPath: string;
}

export function WorkTimeDetailsMain(
    props: RouteComponentProps & IWorkTimeDetailsMainProps
) {
    const [workShiftsLoaded, setWorkShiftsLoaded] = React.useState<boolean>(false);
    const [workShifts, setWorkShifts] = React.useState<WorkShiftDto[]>();
    const [loadingDayBookings, setLoadingDayBookings] = React.useState<boolean>(false);
    const { employeeId, date, workShiftId, tab } =
        useParams<IWorkTimeDetailsPage>();
    const [allEmployeeItems, setAllEmployeeItems] =
        React.useState<EmployeeListItemDto[]>([]);
    const [reloadListKey, reloadList] = useReload();
    const [editDayBookingItem, setEditDayBookingItem] =
        React.useState<DayBookingItemBeta>();
    const [dayBookingItems, setDayBookingItems] = React.useState<DayBookingItemBeta[]>();
    const [selectedSalaryPeriodItem, setSelectedSalaryPeriodItem] =
        React.useState<SalaryPeriodItem>();
    const dispatch = useAppDispatch();
    const [dayBookingTypes, setDayBookingTypes] = useState<DayBookingTypeItemBeta[]>();
    const [salaryRowTypes, setSalaryRowTypes] = useState<ISalaryRowType[]>();
    const [settings, setSettings] = useState<TESCalculationParameters>();
    const [employee, setEmployee] = React.useState<EmployeeListItemDto>();
    const [daybookingFillingHourSalaryRowTypeId, setDaybookingFillingHourSalaryRowTypeId] = React.useState(null);
    const { t } = useTranslation();
    const ownerParameters = new OwnerParameters();
    const workShiftEditEnabled = ownerParameters.getOptionWorkShiftDateChangeAllowed();
    const [formOpen, setFormOpen] = React.useState(false);

    const workShift = React.useMemo(
        () => workShifts?.find((s) => s.workShiftId === workShiftId),
        [workShifts, workShiftId]
    );

    const workShiftTimeSlots = workShift?.workShiftTimeSlots ?? [];
    const noWorkTimes = workShiftTimeSlots.length === 0;

    useEffect(() => {
        if (workShiftsLoaded) {
            setFormOpen(noWorkTimes);
        }
    }, [workShiftsLoaded, noWorkTimes]);

    useFetch(
        (signal) => getEmployees(null, signal),
        {
            onSuccess: (res) => setAllEmployeeItems(res.data),
        },
        []
    );

    useEffect(() => {
        if (date && employeeId) {
            const abortController = new AbortController();
            setWorkShiftsLoaded(false);

            getWorkShiftsByTimeRange(
                employeeId,
                date,
                date,
                true,
                abortController.signal
            )
                .then((res) => {
                    setWorkShifts(res);
                    // Add slots to store for redux use.
                    dispatch(
                        setWorkShiftTimeSlots(
                            new WorkShiftTimeSlotItems({
                                items: res.flatMap((d) => d.workShiftTimeSlots),
                                hasMore: false,
                                page: 1,
                            })
                        )
                    );
                })
                .catch((e) => {
                    if (!abortController.signal.aborted) {
                        showApiError(e);
                    }
                })
                .finally(() => setWorkShiftsLoaded(true));

            return () => {
                abortController.abort();
            };
        }
    }, [employeeId, date, reloadListKey]);

    const fetchStartDate = Base.dayjsToJsonDate(
        Base.minDate([
            Base.dateStartOf(Base.dateStartOf(date, "month"), "week"),
            selectedSalaryPeriodItem?.startDate,
        ])
    );

    const fetchEndDate = Base.dayjsToJsonDate(
        Base.maxDate([
            Base.dateEndOf(Base.dateEndOf(date, "month"), "week"),
            selectedSalaryPeriodItem?.endDate,
        ])
    );

    useAsyncThunkAction(
        () =>
            normalWorkHours({
                employeeId,
                startDate: fetchStartDate,
                endDate: fetchEndDate,
            }),
        {},
        [employeeId, fetchStartDate, fetchStartDate, reloadListKey]
    );

    useAsyncThunkAction(
        () => {
            if (
                selectedSalaryPeriodItem?.startDate &&
                selectedSalaryPeriodItem?.endDate
            ) {
                return calculatedWorkHours({
                    employeeId,
                    salaryPeriodId: selectedSalaryPeriodItem?.id,
                    startDate: selectedSalaryPeriodItem?.startDate,
                    endDate: selectedSalaryPeriodItem?.endDate,
                });
            }
        },
        {},
        [employeeId, selectedSalaryPeriodItem?.id, reloadListKey]
    );

    useAsyncThunkAction(
        // Used in form and timeline
        () => fetchWorkTimeTypes(),
        { onError: (e) => handleApiError(e, dispatch) },
        []
    );

    const loadDayBookings = async() => {
        setLoadingDayBookings(true);
        try {
            const result = await getDayBookingItems(
                date,
                date,
                employeeId
            );
            setDayBookingItems(result);
        } catch (error) {
            dispatch(
                storeActions.showErrorMessage(
                    baseService.getErrorMessageFromError(error)
                )
            );
        } finally {
            setLoadingDayBookings(false);
        }
    };

    const loadDayBookingTypes = async() => {
        try {
            const result = await dayBookingTypeService.getDayBookingTypes();
            setDayBookingTypes(result);
        } catch (error) {
            store.customStore.dispatch(
                storeActions.showErrorMessage(
                    baseService.getErrorMessageFromError(error)
                )
            );
        }
    };

    const loadSalaryRowTypes = () => {
        getSalaryRowTypes().then(
            (result) => {
                setSalaryRowTypes(result);
            },
            (error) => {
                handleApiError(error, store.customStore.dispatch);
            }
        );
    };

    const loadTesCalculationSettings = () => {
        if (employee) {
            getFunctionSettings(employee.salaryGroupId)
                .then((response) => {
                    setSettings(response);
                })
                .catch(showError)
                .finally(() => {});
        }
    }

    useEffect(() => {
        void loadDayBookingTypes();
        void loadSalaryRowTypes();
    }, []);

    useEffect(() => {
        if (salaryRowTypes) {
            let fillingHourSalaryRowType = salaryRowTypes.find((t) =>
                t.hourBookingCategories.some((c) =>
                    [HourBookingCategory.FillingHour].includes(parseInt(c))
                )
            );
            if (fillingHourSalaryRowType) {
                setDaybookingFillingHourSalaryRowTypeId(
                    fillingHourSalaryRowType.id
                );
            }
        }
    }, [salaryRowTypes]);

    useEffect(() => {
        if (date) {
            void loadDayBookings();
        }
    }, [employeeId, date, reloadListKey]);

    useEffect(() => {
        const selectedEmployee = allEmployeeItems.find(allEmployeeItem => allEmployeeItem.id === employeeId);
        if (selectedEmployee) {
            setEmployee(selectedEmployee)
        }
    }, [employeeId, allEmployeeItems, reloadListKey]);

    useEffect(() => {
        void loadTesCalculationSettings();
    }, [employee?.salaryGroupId, reloadListKey]);

    const currentDayWorkShifts = workShifts?.filter(
        (s) => s.effectiveDate === date
    );

    if (workShiftsLoaded && currentDayWorkShifts?.length > 0 && !workShift) {
        return (
            <Redirect
                to={generatePath(props.match.path, {
                    employeeId,
                    date,
                    tab,
                    workShiftId: currentDayWorkShifts[0].workShiftId,
                })}
            />
        );
    }

    const noDayBookings = dayBookingItems?.length === 0;
    const allWorkTimesLocked = !noWorkTimes && workShiftTimeSlots.every(slot => slot.state === 2); // WorkShiftTimeSlotState.Locked

    return date && employeeId && dayBookingItems && dayBookingTypes ? (
        <WorkTimeDetailsLayout
            calendarComponent={
                <WorkTimeDetailsCalendar
                    path={props.match.path}
                    reloadKey={reloadListKey}
                />
            }
            mapComponent={
                noWorkTimes ? null : (
                    <WorkTimeDetailsMap
                        workShiftTimeSlots={workShiftTimeSlots}
                    />
                )
            }
            topComponent={
                <>
                    <Grid2 container columns={36} alignItems="center">
                        <Grid2 xs={1}>
                            <IconButton
                                style={{ backgroundColor: "transparent" }}
                                onClick={() =>
                                    customHistory.push(props.backPath)
                                }
                            >
                                <ArrowBackIcon color="primary" />
                            </IconButton>
                        </Grid2>
                        <Grid2 xs={8} paddingLeft={3}>
                            <MuiSelect
                                label={t("employee.employee")}
                                value={employeeId}
                                options={allEmployeeItems.map((i) => ({
                                    value: i.id,
                                    label: i.fullName,
                                    group: i.salaryGroupName,
                                }))}
                                onChange={(value: string) => {
                                    if (value && value !== employeeId) {
                                        customHistory.push(
                                            generatePath(props.match.path, {
                                                employeeId: value,
                                                date,
                                                tab,
                                            })
                                        );
                                    }
                                }}
                            />
                        </Grid2>
                        {workShifts?.length > 0 ? (
                            <Grid2 xs={8} paddingLeft={3}>
                                <MuiSelect
                                    disabled={
                                        workShifts.length === 1 && !!workShift
                                    }
                                    label={t("workShift")}
                                    value={workShiftId}
                                    options={workShifts
                                        .map((s) => ({
                                            value: s.workShiftId,
                                            label: `${
                                                s.effectiveDate !== date
                                                    ? Base.dateStrToOriginalTimezoneDateTimeStr(
                                                          s.startTime
                                                      )
                                                    : formatTime(
                                                          s.startTime,
                                                          date
                                                      )
                                            } - ${
                                                !s.endTime
                                                    ? ""
                                                    : s.effectiveDate !== date
                                                    ? Base.dateStrToOriginalTimezoneDateTimeStr(
                                                          s.endTime
                                                      )
                                                    : formatTime(
                                                          s.endTime,
                                                          date
                                                      )
                                            }`,
                                            group: s.effectiveDate,
                                            groupLabel: Base.dayjsToDateStr(
                                                s.effectiveDate
                                            ),
                                            groupLinkPath:
                                                s.effectiveDate === date
                                                    ? null
                                                    : generatePath(
                                                          props.match.path,
                                                          {
                                                              employeeId,
                                                              date: s.effectiveDate,
                                                              tab,
                                                          }
                                                      ),
                                            disabled: s.effectiveDate !== date,
                                        }))
                                        // Hack to sort current day group as first
                                        .sort((a, b) =>
                                            (a.group === date
                                                ? `0${a.group}`
                                                : a.group
                                            ).localeCompare(
                                                b.group === date
                                                    ? `0${b.group}`
                                                    : b.group
                                            )
                                        )}
                                    onChange={(value: string) => {
                                        if (value && value !== workShiftId) {
                                            customHistory.push(
                                                generatePath(props.match.path, {
                                                    employeeId,
                                                    date,
                                                    tab,
                                                    workShiftId: value,
                                                })
                                            );
                                        }
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        {!!workShift && workShiftEditEnabled ? (
                            <Grid2 container xs={4} xl={2} paddingLeft={2}>
                                <PrivateRoute
                                    component={
                                        WorkTimeDetailsWorkShiftDateSelect
                                    }
                                    componentProps={{
                                        workShift,
                                        afterSave: reloadList,
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        <Grid2
                            container
                            gap={1}
                            direction="column"
                            xsOffset="auto"
                            alignItems="end"
                        >
                            <Grid2>
                                <WorkHoursPerSalaryPeriod
                                    allEmployeeItems={allEmployeeItems}
                                    selectedSalaryPeriodItem={
                                        selectedSalaryPeriodItem
                                    }
                                    setSelectedSalaryPeriodItem={
                                        setSelectedSalaryPeriodItem
                                    }
                                />
                            </Grid2>
                            {settings?.isFillingHourCalculationOn && (
                                <Grid2>
                                    <FillingHoursPerSalaryPeriod
                                        allEmployeeItems={allEmployeeItems}
                                        selectedSalaryPeriodItem={
                                            selectedSalaryPeriodItem
                                        }
                                        salaryRowTypeId={
                                            daybookingFillingHourSalaryRowTypeId
                                        }
                                    />
                                </Grid2>
                            )}
                            <Grid2>
                                <WorkHoursPerWeek />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </>
            }
            formComponent={
                <PrivateRoute
                    component={WorkTimeDetailsFormTab}
                    componentProps={{
                        afterSave: reloadList,
                        editDayBookingItem: editDayBookingItem,
                        setEditDayBookingItem: setEditDayBookingItem,
                        workShiftTimeSlotItems: workShiftTimeSlots,
                        open: formOpen,
                        salaryRowTypes: salaryRowTypes,
                    }}
                />
            }
            radioButtonComponent={
                noWorkTimes && noDayBookings ? null : (
                    <DayAllowanceSelect
                        key={`${date}-${employeeId}`}
                        reloadList={reloadList}
                        dayBookingTypes={dayBookingTypes}
                        dayBookingItems={dayBookingItems}
                        allWorkTimesLocked={allWorkTimesLocked}
                        loadingDayBookings={loadingDayBookings}
                    />
                )
            }
            timelineComponent={
                noWorkTimes ? null : (
                    <WorkTimeTimeline
                        reloadList={reloadList}
                        workShiftTimeSlotItems={workShiftTimeSlots}
                    />
                )
            }
            mainComponent={
                noWorkTimes ? null : (
                    <>
                        {!workShiftsLoaded ? <LoadingIndicator /> : null}
                        <WorkTimeDetailsList
                            reloadList={reloadList}
                            workShiftTimeSlotItems={workShiftTimeSlots}
                            salaryRowTypes={salaryRowTypes}
                        />
                    </>
                )
            }
            bottomComponent={
                noDayBookings ? null : (
                    <WorkTimeDetailsDayBookingList
                        reloadList={reloadList}
                        dayBookingItems={dayBookingItems}
                        setEditDayBookingItem={setEditDayBookingItem}
                        dayBookingTypes={dayBookingTypes}
                    />
                )
            }
        />
    ) : (
        <div>{Translations.Loading}...</div>
    );
}
