import React, { useMemo } from "react";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import VehicleCard from "./vehicleCard/vehicleCard";
import { SidebarProps, VehicleData } from "../../models/monitorView/monitorViewTypes";
import VehicleFilters from "./vehicleCard/vehicleFilters";

export const MonitorViewVehiclesSidebar = ({
    data,
    selectedId: selectedVehicleId,
    setSelectedId: setSelectedVehicleId,
    countByDrivingStatus,
    selectedFilter,
    setSelectedFilter
}: SidebarProps<VehicleData>) => {
    const { t } = useTranslation();

    const filteredData = useMemo(() => {
        if (selectedFilter === "All") return data;
        return data.filter((vehicle) => vehicle.driving === selectedFilter);
    }, [data, selectedFilter]);

    return (
        <>
            <VehicleFilters selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} countByDrivingStatus={countByDrivingStatus} />
            {filteredData?.length > 0 ? (
                filteredData.map((v) => (
                    <VehicleCard
                        key={v.vehicleId}
                        vehicle={v}
                        driver={v?.employeeName}
                        isSelected={v.vehicleId === selectedVehicleId}
                        onClick={() => setSelectedVehicleId(v.vehicleId)}
                    />
                ))
            ) : (
                <Alert variant="standard" severity="info">
                    {t("vehicle.noRows")}
                </Alert>
            )}
        </>
    );
};