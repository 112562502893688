import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import TimerIcon from "@mui/icons-material/Timer";
import { useTranslation } from "react-i18next";
import { WorkShift } from "../../models/workShift/workShift";
import { showApiError } from "../framework/formUtils";
import { saveWorkShift } from "../../services/workShiftService";
import { Button } from "@mui/material";
import { EditDialog } from "../framework/editDialog";
import { MuiDateTimePicker } from "../framework/muiDateTimePicker";
import { ApiError } from "../../services/baseService";
import * as StoreActions from "../../models/store/storeActions";
import { useAppDispatch } from "../../framework/customStore";
import { customHistory } from "../../framework/customHistory";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";

interface WorkTimeDetailsWorkShiftDateSelectProps {
    workShift: WorkShift;
    afterSave: () => void;
}

export function WorkTimeDetailsWorkShiftDateSelect({
    workShift,
    afterSave,
    match,
}: WorkTimeDetailsWorkShiftDateSelectProps & RouteComponentProps) {
    const { employeeId, tab, workShiftId } = useParams<IWorkTimeDetailsPage>();
    const { t } = useTranslation();
    const [workShiftDate, setWorkShiftDate] = useState<string>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useAppDispatch();

    const saveDisabled =
        !workShiftId ||
        !workShiftDate ||
        workShift?.effectiveDate === workShiftDate;

    useEffect(() => {
        if (!!workShift && workShift?.effectiveDate !== workShiftDate) {
            setWorkShiftDate(workShift?.effectiveDate);
        }
    }, [workShift]);

    const saveWorkShiftDate = () => {
        if (!saveDisabled) {
            saveWorkShift(workShiftId, workShiftDate)
                .then(() => {
                    customHistory.push(
                        generatePath(match.path, {
                            employeeId,
                            date: workShiftDate,
                            tab,
                            workShiftId,
                        })
                    );
                    afterSave();
                })
                .catch((error: ApiError) => {
                    if (!!error.message) {
                        showApiError(error);
                    } else {
                        dispatch(
                            StoreActions.showErrorMessage(
                                error.status === 404
                                    ? t("workTime.workShiftNotFound")
                                    : t("unexpectedError")
                            )
                        );
                    }
                });
        }
    };

    const handleClose = () => {
        setWorkShiftDate(workShift?.effectiveDate);
        setDialogOpen(false);
    };

    return (
        <>
            <IconButton
                color="primary"
                size="large"
                onClick={() => setDialogOpen(true)}
            >
                <TimerIcon />
            </IconButton>
            {dialogOpen ? (
                <EditDialog
                    open={true}
                    title={t("workShift")}
                    onClose={handleClose}
                    body={
                        <>
                            <Alert
                                severity="info"
                                sx={{
                                    mb: 3,
                                    whiteSpace: "pre-line",
                                    alignItems: "center",
                                }}
                            >
                                {t("workTime.workShiftDateHelp")}
                            </Alert>
                            <MuiDateTimePicker
                                required
                                dateOnly
                                dateLabel={t("workTime.workShiftDate")}
                                value={workShiftDate}
                                onChange={setWorkShiftDate}
                                fullWidth
                            />
                        </>
                    }
                    primaryActions={[
                        <Button
                            key="save"
                            color="success"
                            disabled={saveDisabled}
                            variant="contained"
                            onClick={saveWorkShiftDate}
                        >
                            {t("common.save")}
                        </Button>,
                        <Button
                            key="cancel"
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            {t("common.cancel")}
                        </Button>,
                    ]}
                />
            ) : null}
        </>
    );
}
