import React, { useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Map, MapPointProps } from "../framework/map/map";
import LocalShippingIcon from "@mui/icons-material/LocalShippingOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    getWorkTimeTypeColorClass,
    getWorkTimeTypeColorCode,
    getWorkTimeTypeContrastColorClass,
} from "../../models/workShitTimeSlot/workTimeType";
import { SectionHeader } from "../layout/sectionHeader";
import { useTranslation } from "react-i18next";
import MuiMenu from "../framework/muiMenu";
import { MuiSwitch } from "../framework/muiSwitch";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { setGroupMapPoints } from "../../store/monitorViewSlice";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { useWorkShiftTimeSlotMapData } from "../../hooks/workTime/useWorkShiftTimeSlotMapData";
import { useReload } from "../../hooks";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { VehicleData } from "../../models/monitorView/monitorViewTypes";

interface MonitorViewMapProps {
    loading: boolean;
    data: VehicleData[];
    onRefresh: () => void;
    selectedVehicleId: string;
    setSelectedVehicle: (id: string) => void;
    workShift: WorkShiftDto;
    reloadKey: number;
    selectedWorkShiftTimeSlotId: string;
    hoveredWorkShiftTimeSlotId: string;
    setSelectedWorkShiftTimeSlotId: (id: string) => void;
}

export const MonitorViewMap = ({
    loading,
    data,
    onRefresh,
    selectedVehicleId,
    setSelectedVehicle,
    workShift,
    reloadKey,
    selectedWorkShiftTimeSlotId,
    hoveredWorkShiftTimeSlotId,
    setSelectedWorkShiftTimeSlotId,
}: MonitorViewMapProps) => {
    const [workShiftPositionsLoading, setWorkShiftPositionsLoading] =
        useState(false);
    const { t } = useTranslation();
    const groupPoints = useAppSelector(
        (state) => state.monitorView.groupMapPoints
    );
    const showNoWorkTime = useAppSelector(
        (state) => state.monitorView.filters.noWorkTime
    );

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const dispatch = useAppDispatch();
    const [zoomKey, zoom] = useReload();
    const shouldZoom = useRef(false);

    const { routes, points, reload, loading: mapDataLoading } = useWorkShiftTimeSlotMapData(
        workShift?.workShiftTimeSlots ?? [],
        selectedWorkShiftTimeSlotId,
        hoveredWorkShiftTimeSlotId
    );

    useEffect(() => {
        reload();
    }, [reloadKey]);

    useEffect(() => {
        if (!!workShift?.workShiftId) {
            setWorkShiftPositionsLoading(true);
            shouldZoom.current = true;
        }
    }, [workShift?.workShiftId]);

    useEffect(() => {
        // Zoom when workShift has changed and routes and points have been loaded
        if (shouldZoom.current && routes?.length > 0 && points?.length > 0) {
            setWorkShiftPositionsLoading(false);
            zoom();
            shouldZoom.current = false;
        }
    }, [routes, points]);

    useEffect(() => {
        // Zoom when filters have changed
        if (!!selectedWorkTimeTypeIds) {
            zoom();
        }
    }, [selectedWorkTimeTypeIds, showNoWorkTime]);

    useEffect(() => {
        setWorkShiftPositionsLoading(mapDataLoading);
    }, [mapDataLoading]);

    const mapPoints = useMemo(
        () =>
            data
                ?.filter(
                    (d) =>
                        // Filter by selected vehicle, if present
                        !selectedVehicleId || selectedVehicleId === d.vehicleId
                )
                .map(
                    (d) =>
                        ({
                            id: d.vehicleId,
                            coords: d.coords,
                            icon: (
                                <LocalShippingIcon
                                    className={`text-${getWorkTimeTypeContrastColorClass(
                                        d.workTimeTypeType
                                    )}`}
                                />
                            ),
                            text: d.registerNumber,
                            fullText: [
                                d.registerNumber,
                                d.employeeName,
                                d.workTimeTypeName,
                            ]
                                .filter(Boolean)
                                .join(" - "),
                            textColorClass: getWorkTimeTypeContrastColorClass(
                                d.workTimeTypeType
                            ),
                            textBgColorClass: getWorkTimeTypeColorClass(
                                d.workTimeTypeType
                            ),
                            groupColor: getWorkTimeTypeColorCode(
                                d.workTimeTypeType
                            ),
                            groupName: d.workTimeTypeName,
                        } as MapPointProps)
                ),
        [data, selectedVehicleId]
    );

    return (
        <Box display="flex" flexDirection="column" minHeight="100%">
            <LoadingIndicator loading={workShiftPositionsLoading} />
            <div>
                <SectionHeader>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        className="section-header text-truncate"
                    >
                        {t("monitor")}
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => zoom()}
                        title={t("map.centerFocus")}
                    >
                        <CenterFocusStrongIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        onClick={onRefresh}
                        disabled={loading}
                    >
                        <RefreshIcon />
                    </IconButton>
                    <MuiMenu
                        label={<SettingsIcon color="white" />}
                        keepOpenAfterSelect
                        items={[
                            {
                                label: (
                                    <MuiSwitch
                                        label={t("map.groupIcons")}
                                        checked={groupPoints}
                                        readOnly
                                        onChange={(_, checked) =>
                                            dispatch(setGroupMapPoints(checked))
                                        }
                                    />
                                ),
                            },
                        ]}
                    />
                </SectionHeader>
            </div>
            {!!mapPoints ? (
                <Map
                    points={!!workShift ? points : mapPoints}
                    routes={!!workShift ? routes : []}
                    autoZoom={false}
                    zoomKey={zoomKey}
                    groupPoints={groupPoints}
                    pointSelectionEnabled
                    onPointSelect={
                        !!workShift
                            ? setSelectedWorkShiftTimeSlotId
                            : setSelectedVehicle
                    }
                    hoverMenuEnabled
                />
            ) : null}
        </Box>
    );
};
