import React, { useMemo } from "react";
import CardContainer from "../cardContainer";
import EmployeeCardContent from "./employeeCardContent";
import { EmployeeData } from "../../../models/monitorView/monitorViewTypes";
import { getWorkTimeTypeColorClass } from "../../../models/workShitTimeSlot/workTimeType";
import { getShiftAndSlotDuration } from "../monitorViewUtils";

interface EmployeeCardProps {
    employee: EmployeeData;
    isSelected: boolean;
    onClick: () => void;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee, isSelected, onClick }) => {
    const workShiftTimeSlotDuration = useMemo(() => getShiftAndSlotDuration(employee.workShifts, employee.employeeId), [employee.workShifts, employee.employeeId]);
    const { shiftDuration, timeSlotDuration } = workShiftTimeSlotDuration;
    return (
        <CardContainer isSelected={isSelected} onClick={onClick}>
            <EmployeeCardContent
                effectiveDate={workShiftTimeSlotDuration.effectiveDate}
                employeeId={employee.employeeId}
                driver={employee.registerNumber ? `${employee.registerNumber} (${employee.brand})` : ""}
                mainTitle={employee.employeeName}
                shiftDuration={shiftDuration}
                timeSlotDuration={timeSlotDuration}
                workTimeStatusIconClass={`text-${getWorkTimeTypeColorClass(employee.workTimeTypeType)}`}
                workTimeTypeName={employee.workTimeTypeName}
            />
        </CardContainer>
    );
};

export default EmployeeCard;