import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { VehicleDriving } from "../../../models/vehicle/vehiclePosition";
import { getVehicleStatusText } from "../monitorViewUtils";

interface VehicleFiltersProps {
    selectedFilter: number | "All";
    setSelectedFilter: (filter: number | "All") => void;
    countByDrivingStatus: { [key: number]: number };
}

const VehicleFilters: React.FC<VehicleFiltersProps> = ({ selectedFilter, setSelectedFilter, countByDrivingStatus }) => {

    const filters = [
        { label: getVehicleStatusText("All"), value: "All", count: Object.values(countByDrivingStatus).reduce((a, b) => a + b, 0) },
        { label: getVehicleStatusText(VehicleDriving.Stopped), value: VehicleDriving.Stopped, count: countByDrivingStatus[VehicleDriving.Stopped] || 0 },
        { label: getVehicleStatusText(VehicleDriving.Moving), value: VehicleDriving.Moving, count: countByDrivingStatus[VehicleDriving.Moving] || 0 },
        { label: getVehicleStatusText(VehicleDriving.Driving), value: VehicleDriving.Driving, count: countByDrivingStatus[VehicleDriving.Driving] || 0 },
        { label: getVehicleStatusText(VehicleDriving.Inactive), value: VehicleDriving.Inactive, count: countByDrivingStatus[VehicleDriving.Inactive] || 0 }
    ];

    return (
        <Box sx={{ bgcolor: "#f5f5f5" }}>
            <Tabs
                value={selectedFilter}
                onChange={(event, newValue) => setSelectedFilter(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
            >
                {filters.map((filter) => (
                    <Tab
                        key={filter.label}
                        value={filter.value}
                        label={
                            <Box sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle2">{filter.count}</Typography>
                                <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>{filter.label}</Typography>
                            </Box>
                        }
                        sx={{ minWidth: 0, padding: "6px" }}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default VehicleFilters;