import { useEffect, useMemo, useState } from "react";
import { Base } from "../../framework/base";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { handleApiError } from "../../models/store/storeEffects";
import { fetchReportVehicles } from "../../store/reportOptionsSlice";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import {
    VehicleEventInfo,
    VehicleEventType,
} from "../../models/vehicle/vehicleEventInfo";
import {
    getVehicleDistance,
    getVehicleTotalFuel,
} from "../../services/vehicleService";
import { ApiResponse } from "../../services/apiClient";

export const VehicleReportOptionsMapping = {
    vehicles: { method: fetchReportVehicles, attr: "vehicles" },
};

export interface VehicleReportData {
    loading: boolean;
    vehicles: VehicleListItemDto[];
    eventData: VehicleEventData;
    presentVehicles: VehicleListItemDto[];
}

export type VehicleEventData = {
    [key in VehicleEventType]: VehicleEventInfo[];
};

type VehicleDataMethod = {
    [key in VehicleEventType]: (
        vehicleIds: string[],
        windowStartTime: string,
        windowEndTime: string,
        signal?: AbortSignal
    ) => Promise<ApiResponse<VehicleEventInfo[]>>;
};

const vehicleDataMethods: VehicleDataMethod = {
    [VehicleEventType.Distance]: getVehicleDistance,
    [VehicleEventType.TotalFuel]: getVehicleTotalFuel,
};

export const useVehicleReportData = (
    selectedOptions: string[]
): VehicleReportData => {
    const [loading, setLoading] = useState(true);
    const [eventData, setEventData] = useState<VehicleEventData>();

    const dispatch = useAppDispatch();

    const timeRange = useAppSelector(
        (state) => state.reportOptions.filters.timeRange
    );

    const vehicles = useAppSelector((state) => state.reportOptions.vehicles);

    const selectedVehicles = useAppSelector(
        (state) => state.reportOptions.filters.selectedVehicles
    );

    const selectedVehicleIds = useMemo(
        () =>
            selectedVehicles?.length > 0
                ? selectedVehicles
                : vehicles?.map((v) => v.id),
        [vehicles, selectedVehicles]
    );

    const loadEventData = async (signal?: AbortSignal) => {
        if (selectedVehicleIds?.length > 0) {
            const data: VehicleEventData = {
                [VehicleEventType.Distance]: [],
                [VehicleEventType.TotalFuel]: [],
            };

            await Promise.all(
                Object.keys(vehicleDataMethods).map((k) =>
                    vehicleDataMethods[k](
                        selectedVehicleIds,
                        Base.dayjsToJsonDateTimeOffset(timeRange[0]),
                        Base.dayjsToJsonDateTimeOffset(
                            Base.dateAddDays(timeRange[1], 1)
                        ),
                        signal
                    )
                        .then((res) => (data[k] = res?.data ?? []))
                        .catch((err) => {
                            if (!signal?.aborted) {
                                handleApiError(err, dispatch);
                                console.log("error", err);
                            }
                        })
                )
            ).finally(() => setEventData(data));
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        const responses = [];
        setLoading(true);

        responses.push(loadEventData(abortController.signal));

        Promise.all(responses).finally(() => setLoading(false));

        return () => {
            abortController.abort();
        };
    }, [timeRange, selectedVehicleIds]);

    useEffect(() => {
        const promises = selectedOptions.map((o) =>
            dispatch(VehicleReportOptionsMapping[o].method())
        );

        return () => {
            promises.forEach((p) => p.abort());
        };
    }, []);

    return {
        loading,
        vehicles,
        eventData,
        presentVehicles: vehicles,
    };
};
