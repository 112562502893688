import React, { useMemo } from "react";
import { t } from "i18next";
import { formatTime, formatWorkTime } from "../../salaries/salariesListGrid";
import { IWorkHoursItem } from "../../../models/workTime/workHoursItem";
import {
    LinkToEmployeeWorkTimeDetails,
    ReportingDetailsTable,
} from "../employee/reportingDetailsTable";
import { StrMeasureUnit } from "../../../models/common/enums";
import { SalaryRowTypeItem } from "../../../models/salary/salaryRowTypeItem";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { ICostCenterItem } from "../../../models/costCenter/costCenterItem";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";

export interface GridRow {
    id: string;
    hierarchy: string[];
    workHourItems: IWorkHoursItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface WorkTimeDetailsProps {
    row: GridRow;
}

interface IWorkHoursItemUnique extends IWorkHoursItem {
    rowId: number;
}

export const WorkTimeDetailsTable = (props: WorkTimeDetailsProps) => {
    const {
        workHourItems,
        salaryRowTypes,
        employees,
        costCenters,
        workTimeTypes,
    } = props.row;

    const workHours = workHourItems.map((wh, i) => {
        return { ...wh, rowId: i } as IWorkHoursItemUnique;
    });

    const columns = useMemo(
        () => [
            {
                header: t("vehicle.startTime"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    LinkToEmployeeWorkTimeDetails(
                        row.employeeId,
                        row.dateStr,
                        formatTime(row.startTime, row.dateStr)
                    ),
            },
            {
                header: t("vehicle.endTime"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    LinkToEmployeeWorkTimeDetails(
                        row.employeeId,
                        row.dateStr,
                        formatTime(row.endTime, row.dateStr)
                    ),
            },
            {
                header: t("vehicle.employee"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    employees.find((e) => e.id === row?.employeeId)?.fullName ??
                    "-",
            },
            {
                header: t("vehicle.amount"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    formatWorkTime(row?.amount, StrMeasureUnit.HourFi, 2),
            },
            {
                header: t("vehicle.salaryRowType"),
                renderCell: (row: IWorkHoursItemUnique) => {
                    const srt = salaryRowTypes.find(
                        (srt) => srt.id === row?.salaryRowTypeId
                    );
                    return srt
                        ? `${srt.code} ${srt.name} (${srt.measureUnit})`
                        : "-";
                },
                colSpan: 2,
            },
            {
                header: t("workTime.costCenter"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    costCenters.find((c) => c?.id === row?.costCenterId)
                        ?.name ?? "-",
            },
            {
                header: t("workTime.workTimeType"),
                renderCell: (row: IWorkHoursItemUnique) =>
                    workTimeTypes.find(
                        (wt) => wt?.workTimeTypeId === row?.workTimeTypeId
                    )?.name ?? "-",
            },
            {
                header: t("vehicle.comment"),
                renderCell: (row: IWorkHoursItemUnique) => row.comment || "-",
            },
        ],
        [costCenters, workTimeTypes, salaryRowTypes, employees]
    );

    return <ReportingDetailsTable columns={columns} rows={workHours} />;
};
